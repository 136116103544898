/* Portfolio.css */

/* Grid Layout for Portfolio */
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 50px;
    padding: 20px;
    justify-content: center;
  }
  
  .card {
    text-align: center;
    cursor: pointer;
    border:3px solid #ccc !important;
  }
  .card  h3{ font-size: 1rem;padding:5px 0}
  .image {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height:200px;
  }

  /* Modal Overlay with Drop Shadow */
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Drop shadow */
  }
  
  /* Modal Content */
  .modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    position: relative;
    animation: fadeIn 0.5s ease-in-out; /* Fade-in animation for modal */
    max-height: 90vh; /* Limit height on large screens */
    overflow-y: auto; /* Make content scrollable if it overflows */
  }
  
  /* Modal header with text animation */
  .modalHeader {
    margin-bottom: 20px;
    text-align: center;
    animation: slideInFromTop 0.5s ease-out; /* Slide-in animation from the top */
  }
  
  /* Flex Container for Image, Key Features, Website Link, and Target Audience */
  .modalFlexContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  /* Image Section */
  .modalImageContainer {
    width: 50%;
    flex: 1;
  }
  
  .modalImage {
    width: 100%;
    border-radius: 8px;
    animation: zoomIn 0.5s ease-in-out; /* Zoom-in effect for image */
  }
  
  /* Text Section */
  .modalText {
    width: 50%;
    text-align: left;
    flex: 1;
    animation: fadeInText 1s ease-in-out; /* Fade-in animation for text */
  }
  
  /* Website and Target Audience Container */
  .websiteAndTargetContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .websiteLink {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .targetAudience {
    text-align: left;
    animation: fadeInText 1.2s ease-in-out; /* Fade-in animation for target audience */
  }
  
  /* Close Button */
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Key Features List */
  ul {
    list-style: none;
    padding: 0;
  }
  
  /* Text Animation Keyframes */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes fadeInText {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes zoomIn {
    0% {
      transform: scale(0.9);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .modalContent {
      width: 95%; /* Adjust modal content width */
      padding: 15px; /* Reduce padding */
      max-height: 80vh; /* Allow more space for modal content on mobile */
      overflow-y: scroll; /* Make the modal content scrollable */
    }
  
    .modalImageContainer {
      width: 100%;
      margin-bottom: 15px; /* Add spacing between image and other content */
    }
  
    .modalText {
      width: 100%;
    }
  
    .websiteAndTargetContainer {
      width: 100%;
    }
  
    .closeButton {
      top: 5px; /* Adjust position for mobile */
      right: 5px;
      font-size: 18px;
    }
  }
  
  @media (max-width: 480px) {
    .modalContent {
      width: 90%; /* Make modal content width slightly smaller on very small screens */
    }
  
    .modalHeader h2 {
      font-size: 18px; /* Reduce font size for smaller screens */
    }
  
    .modalHeader p {
      font-size: 14px; /* Reduce description text size */
    }
  
    .modalImage {
      max-height: 250px; /* Limit image size on smaller screens */
    }
  
    .modalText h4,
    .websiteLink,
    .targetAudience h4 {
      font-size: 16px; /* Smaller font size for text */
    }
  }
  