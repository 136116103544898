
  /* Make all cards have equal height */
.course-card {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
  }
  
  .card-text {
    flex-grow: 1; /* This ensures that the text content grows to take available space */
  }
  