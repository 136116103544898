/* app.css */

/* Base styles */
.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  background-color: #fff;
  color: #fff;
  padding: 5px 0;
  /* border-bottom:10px solid rgb(175, 225, 253); */
 
}

.logo {
  font-size: 24px;
}

.navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.container-fluid{
  margin-left: 0px !important;
}

.navigation ul li {
  display: inline;
  margin-right: 20px;
}

.navigation ul li:last-child {
  margin-right: 0;
}

.navigation ul li a {
  color: #fff;
  text-decoration: none;
}

.footer {
  background-color: #3498db;
  color: #fff;
  padding: 5px 0;
  text-align: center;
}

.home .content {
  padding: 20px 0;
}
.padRL10{Padding: 0 10px !important}

.ml-3{margin-left:2rem}
/* Responsive styles */
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
  .ml-3{margin-left:0rem}
}

@media (max-width: 576px) {
  .logo {
    font-size: 20px;
  }

  .navigation ul li {
    margin-right: 10px;
  }
  
}

@media (min-width: 992px) {
  .col-lg-4 {
      flex: 0 0 auto;
      width: 31%;
  }
}
/*social media styles*/

.social-icons-container {
  display: flex;
  justify-content: flex-end;
  padding:10px 0;
}

.social-icons {
  display: flex;
  gap: 20px; /* Adjust the space between icons */
  margin-right:20px
}

.social-icons a {
  color: #fff; /* Adjust icon color */
  font-size: 20px; /* Adjust icon size */
}

/* Optional: You can add hover effects or any other styles here */
.social-icons a:hover {
  color: #007bff; /* Change color on hover */
}


/*** Service ***/
.service-item {
  position: relative;
  height: 300px;
  padding: 0 30px;
  transition: .5s;
}

.service-item .service-icon {
 
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary);
  border-radius: 2px;
  transform: rotate(-45deg);
}

*, ::after, ::before {
  box-sizing: content-box;
}

.service-item .service-icon i {
  transform: rotate(45deg);
  font-size: 2em;
}


.service-item a.btn {
  position: absolute;
  width: 60px;
  bottom: -48px;
  left: 50%;
  margin-left: -30px;
  opacity: 0;
}

.service-item:hover a.btn {
  bottom: -24px;
  opacity: 1;
}

@media (max-width: 768px) {
  .container .collapse.navbar-collapse.show {
    width: 30%;
    position: relative;
    text-align: right;
  }

  .row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    Padding:0px !important;
   margin-top: var(--bs-gutter-y);
}

.container, .container-fluid, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 90%;
  Padding:10px 0px !important;
  margin-right: auto;
  margin-left: auto;
}
.carousel-caption{ width:90%; height:fit-content}
.carousel-caption h3{
  color: #fdf903;
  font-size: 2rem;
  
 

}
.carousel-caption > p{
color:rgb(20, 86, 240);
font-size:.75rem;

}
/* .navbar-brand{
  width: 50px !important;
   height: 40px !important;
} */
}

/* Courses.css */
.custom-heading {
  background-color: #3498db; /* Blue background */
  color: black; /* Black text */
  padding: 10px;
  border-radius: 5px;
}

.course-card {
  background-color: #f8f9fa; /* Light background like a table */
  border: 1px solid #dee2e6; /* Border to mimic table cells */
  border-radius: 0.25rem; /* Slightly rounded corners */
}

.course-card .card-body {
  padding: 1.25rem;
}


/* ContactUs.css */
.card-title {
  font-weight: bold;
}

.card-text {
  font-size: 1rem;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.me-2 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.text-center {
  text-align: center;
}

.bg-primary {
  background-color: #3498db !important; /* Blue theme */
}

.position-relative {
  position: relative;
}
.top-10 {
  top: 10px;
}


@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes slideIn {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

.carousel-caption h3 {
  animation: slideIn 1s ease-out forwards, fadeIn 1s ease-out forwards;
  opacity: 0;
  font-size: 2.5rem;
}

.carousel-caption p {
  animation: fadeIn 1.5s ease-out forwards;
  opacity: 0;
  animation-delay: 0.5s; /* Delay the paragraph animation */
  font-size: 1.5rem;
}
/* clients.css */

.client-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px; /* Set a fixed height for logo container */
  background-color: #f7f7f7; /* Light grey background */
  border-radius: 5px; /* Rounded corners */
  padding: 10px;
  transition: transform 0.3s ease;
}

.client-logo img {
  max-height: 80px; /* Ensure the logo fits inside the container */
  max-width: 100%;
}

.client-logo:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
}
.bg-top,.bg-footer{ background-color: #024bad !important;}


/* Career Details Section with smoother animation */
.career-details {
  height: 0; /* Start with zero height */
  overflow: hidden; /* Hide overflowing content */
  opacity: 0;
  transform: translateY(-10px); /* Optional: adding a slight upward slide for smoother effect */
  transition: height 0.7s ease-out, opacity 0.7s ease-out, transform 0.7s ease-out; /* Transition for all properties */
}

.career-details.open {
  height: auto; /* Allow the height to expand based on content */
  opacity: 1;
  transform: translateY(0); /* Slide back into place */
}

/* Optional: Add a transition to the container */
.card-body {
  transition: transform 0.3s ease-in-out;
}

.card-body:hover {
  transform: scale(1.01); /* Slight hover effect on card */
 
}
.nav-link{font-weight: bold;}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto /* Optional: to center vertically as well */
}
.center-container img{ height:150px}
.home .content .banner-image{ display:none}
.home .content .container{ padding:0px auto !important}
/*ServicesList*/

.card {
  border: none;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: rgb(243, 243, 243) !important;
}


.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.icon i {
  background-color: #3498db !important;
  padding: 15px;
  border-radius: 50%;
  display: inline-block;
  color: white;
}

.service-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure cards have the same height */
}
.custom-grid {
  row-gap: 24px; /* Adjust row gap */
  column-gap: 16px; /* Adjust column gap */
  --bs-gutter-x: 0.5rem;
}
.h-100 {
  height: 90% !important;
}
.card-body .fa-3x {
  font-size: 2em;
}

/* nav link */




.header .nav-link.active {
  color: #686b6d; /* Active link color from react-router-dom */
}

.header .nav-link.clicked-active {
  color: #3bb6ff; /* Custom color for clicked link */
  font-weight: bold;
}
.header .nav-link {
  color: #0d6efd;
  position: relative;
  transition: color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

/* Animation effect when hovering */
.header .nav-link:hover {
  color: #686b6d; /* Change color */
  transform: translateY(-2px); /* Slight upward movement */
}

/* Add underline animation */
.header .nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; /* Position slightly below the text */
  width: 0;
  height: 2px;
  background-color: #686b6d;
  transition: width 0.3s ease;
}

/* Extend underline on hover */
.header .nav-link:hover::after {
  width: 100%; /* Full underline width */
}
.drop-shadow {filter: drop-shadow(10px 10px 5px rgb(157, 157, 158))}
/* Add this CSS to your stylesheet */
ul {
  list-style-type: disc; /* For standard round bullets */
  padding-left: 20px;     /* Space for the bullets */
}

ul.custom-bullets li {
  list-style-type: disc; /* Change the bullet to square */
}

.banner-image {
  position: relative;
  overflow: hidden;
}

.banner-image img {
  animation: fadeInZoom 2s ease-in-out;
}

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.mr-3{ margin-right:20px}

