.carousel {
  position: relative;
  width: 100%;
  margin: auto;
  overflow: hidden;
  border-radius: 10px;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.carousel-slide {
  min-width: 100%;
  height: 430px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Navigation Buttons */
.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease-in-out;
}

.carousel-button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .carousel-slide {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .carousel-slide {
    height: 300px;
  }
}

@media (max-width: 480px) {
  .carousel-slide {
    height: 250px;
  }

  .carousel-button {
    font-size: 18px;
    padding: 8px 12px;
  }
}
